import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

import { FileActionsDictionary } from './../dictionaries/FileActions';
import { folderColors } from './../dictionaries/folderColors';
import { EncrDecrService } from './encr-decr.service';

@Injectable({
  providedIn: 'root',
})
export class UtilsService {
  folderColors: typeof folderColors;
  constructor(
    private http: HttpClient,
    private encrdecr_$: EncrDecrService,
  ) {
    this.folderColors = folderColors;
  }

  postRequest(cloudFunctionUrl: string, body: { email: any; password: string; displayName: any; role: any; uid: any }) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    };

    return this.http.post(cloudFunctionUrl, body, httpOptions);
  }

  getFileMimeType(file: File): Promise<string> {
    const getMimetype = signature => {
      switch (signature) {
        case '89504E47':
          return 'png';
        case '47494638':
          return 'gif';
        case '25504446':
          return 'pdf';
        case '424D8A7B':
          return 'bmp';
        case '49492A0':
          return 'tiff';
        case 'FFD8FFDB':
        case 'FFD8FFE0':
        case 'FFD8FFE1':
          return 'jpeg';
        case '001BA':
          return 'vob';
        case '52494646':
          return 'wav';
        case '4944334':
          return 'mp3';
        case '2247616D':
          return 'csv';
        case '54686973':
          return 'docx';
        case '504B34':
          return 'docx,pptx,xlsx';
        case 'D0CF11E0':
          return 'doc,xls,ppt,msi,msg';
        case '00018':
        case '00020':
          return 'mp4';
        default:
          return undefined;
      }
    };

    return new Promise((resolve, reject) => {
      const filereader = new FileReader();
      filereader.onloadend = (evt: any) => {
        if (evt.target.readyState === FileReader.DONE) {
          const uint = new Uint8Array(evt.target.result);
          const bytes = [];
          uint.forEach(byte => {
            bytes.push(byte.toString(16));
          });
          const hex = bytes.join('').toUpperCase();
          resolve(getMimetype(hex.toString()));
        }
      };

      filereader.onerror = () => {
        filereader.abort();
        reject(new Error(`The file couldn't be loaded`));
      };

      const blob = file.slice(0, 4);
      filereader.readAsArrayBuffer(blob);
    });
  }

  getChunks(chunkSize: number, arr: any[]) {
    const chunks = [];
    for (let i = 0; i < arr.length; i += chunkSize) {
      chunks.push(arr.slice(i, i + chunkSize));
    }
    return chunks;
  }

  setNewPassword(pwd: string, params) {
    const url = `${environment.constants.cloudfunctionsURL}admin-setNewPasswordV2`;
    const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) };
    const psw = this.encrdecr_$.set('123456$#@$^@1ERF', pwd);
    return this.http.post(url, { uc: params.code, cd: psw }, httpOptions);
  }

  setNewPasswordSignUp(pwd: string, params) {
    // const url = `https://admin-setnewpasswordv2signup-y2tqhjf27a-uc.a.run.app`;
    const url = `${environment.constants.cloudfunctionsURL}admin-setNewPasswordV2SignUp`;
    const psw = this.encrdecr_$.set('123456$#@$^@1ERF', pwd);
    const body = { uc: params.code, cd: psw };
    const options = { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) };
    return this.http.post(url, body, options);
  }

  getButtonStyle(name) {
    if (!name) {
      return;
    }
    const n = name.split('(')[0].trim();
    const background = this.folderColors[n] || this.folderColors['custom'];
    return { background };
  }

  getButtonColor(name) {
    if (!name) {
      return;
    }
    return this.folderColors[name] || this.folderColors['custom'];
  }

  checkCookie() {
    const cookieEnabled = navigator.cookieEnabled;
    return cookieEnabled;
    // if (!cookieEnabled){
    //     document.cookie = "testcookie";
    //     cookieEnabled = document.cookie.indexOf("testcookie")!=-1;
    // }
    // return cookieEnabled || showCookieFail();
  }

  createNotificationEmail(action, row, email: string) {
    const emailBody = this.createEmailBody(action, row, email);
    let subject;
    switch (action) {
      case 'UNSHARE':
        subject = '[NuageDx notifications]: A file has been unshared';
        break;
      case 'SHARE':
        subject = '[NuageDx notifications]: A file has been shared';
        break;
      default:
        subject = '[NuageDx] Simple message';
        break;
    }
    this.simpleEmail(emailBody, [email], subject);
  }

  generateValidationCode() {
    // NOTE: Generate the code.
    const random = (min = 0, max = 50) => {
      const num = Math.random() * (max - min) + min;
      return Math.floor(num);
    };
    const code = random(100000, 999999);
    return code;
  }

  validateEmail(email) {
    const re =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  }

  simpleEmail(body: string, dests: string[], subject, attachment?) {
    const url = `${environment.constants.cloudfunctionsURL}email-simpleEmailV3`;
    const dest = dests.join(',');

    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: 'secret-key',
      }),
    };

    return this.http
      .post(url, { dest, body, subject }, httpOptions)
      .toPromise()
      .catch(error => {
        console.error('An error occurred while sending the email:', error);
        throw error;
      });
  }

  validatePasswordResetRequest(code: string) {
    const url = `${environment.constants.cloudfunctionsURL}admin-validatePasswordResetRequestV3`;
    // const url = `${environment.constants.cloudfunctionsURL}admin-validatePasswordResetRequest`;
    const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) };
    return this.http.post(url, { code: code }, httpOptions);
  }

  validatePasswordResetRequestV2(code: string) {
    const url = `${environment.constants.cloudfunctionsURL}admin-validatePasswordResetRequestV2`;
    const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) };
    return this.http.post(url, { code: code }, httpOptions);
  }

  setPasswordResetRequest(userdocid: string, body) {
    const url = `${environment.constants.cloudfunctionsURL}admin-setPasswordResetRequest`;
    // const dest = dests.join(',');

    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        // 'Authorization': 'secret-key'
      }),
    };

    const data = {
      userdocid: userdocid,
      code: body.code,
      type: body.type,
      useremail: body.useremail,
      date: new Date().toString(),
    };

    return this.http.post(url, data, httpOptions);
  }

  createEmailBody(action, file, email: string) {
    let bodyText;
    switch (action) {
      case FileActionsDictionary.SHARE:
        bodyText = `A file has been shared:\nShared with: ${email}.\nFile name: ${
          file.fileName === 'DICOMDIR' ? 'Imaging Study' : file.fileName
        }.\nCase name: ${file.belongsTo}.\nFolder name: ${file.parentFolderName}.`;
        break;
      case FileActionsDictionary.UNSHARE:
        bodyText = `A file has been unshared:\nUnshared with: ${email}.\nFile name: ${
          file.fileName === 'DICOMDIR' ? 'Imaging Study' : file.fileName
        }.\nCase name: ${file.belongsTo}.\nFolder name: ${file.parentFolderName}.`;
        break;
      default:
        break;
    }
    return bodyText;
  }

  isContained(matrix, string) {
    const located = matrix.indexOf(string);
    if (located > -1) {
      const string1Place = located + string.length;
      const matrixLength = matrix.length;
      return string1Place === matrixLength;
    } else {
      return false;
    }
  }

  cleanFilePath(item) {
    return item.ReferencedFileID.replace(/\.\\/g, '/').replace(/\\/g, '/').replace(/\/\//g, '/');
  }

  filterFilesByExt(files, allowedExts) {
    return files.filter(file => (file && file.name ? this.hasValidExt(file.name, allowedExts) : false));
  }

  buildChildren(ugid, inprogress, blockFiles, currentFolder?) {
    const children = [];
    blockFiles.forEach(file => {
      const target = file.entryMeta.targetFolderId
        ? { folderId: file.entryMeta.targetFolderId, folderName: file.entryMeta.targetFolderName }
        : currentFolder
          ? currentFolder
          : '';

      const childObject = {
        name: file.entry.name,
        fdate: file.entry.fdate,
        edit: false,
        ugid: ugid,
        fileUploadCode: file.fileUploadCode,
        desc: file.entryMeta.desc || '',
        entryUID: file.entryMeta.entryUID,
        type: file.entry.studyDate || file.entry.studyTime ? 'dicom' : 'file',
        inprogress: inprogress,
        target: target,
      };

      if (file.toUpload === false) childObject['toUpload'] = false;

      children.push(childObject);
    });
    return children;
  }

  generateUniqueId(length, preString) {
    let result = '';
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    const charactersLength = characters.length;
    for (let i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return `${preString}_${result}`;
  }

  getFileExtension(filename: string) {
    const dotLocation = filename.lastIndexOf('.');
    if (dotLocation === -1) {
      return '';
    } else {
      return filename.substring(dotLocation + 1).toLowerCase();
    }
  }

  /**
   * dateToString transform a date string (Y,m,d) into a (m,d,Y) string.
   */
  dateToString(datestring: string) {
    const year = parseInt(datestring.substr(0, 4), 10);
    const month = parseInt(datestring.substr(4, 2), 10);
    const day = parseInt(datestring.substr(6, 2), 10);
    return `${month}/${day}/${year}`;
  }

  getRandomString() {
    return Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15);
  }

  getRandomString_(stringLength: number = 10) {
    const chars = '0123456789ABCDEFGHIJKLMNOPQRSTUVWXTZabcdefghiklmnopqrstuvwxyz';
    let randomstring = '';
    for (let i = 0; i < stringLength; i++) {
      const rnum = Math.floor(Math.random() * chars.length);
      randomstring += chars.substring(rnum, rnum + 1);
    }
    return randomstring;
  }

  breakInSubsArrs(arr: any[], size: number) {
    const quantity = Math.ceil(arr.length / size);
    const bigArr = [];
    for (let d = 0; d < quantity; d++) {
      bigArr.push(arr.splice(0, size));
    }
    return bigArr;
  }

  getNewStoreName(filename: string, date: string) {
    const datePrimitive = new Date().valueOf();
    // TODO: save this storename in firebase.
    return `${filename}_${date}_${datePrimitive}`;
  }

  getNewSetName(caseName: string, date: string) {
    const randNum = Math.floor(Math.random() * Math.floor(950));
    return `${caseName}_${date}_${randNum}`;
  }

  cleanString(string: string) {
    if (!string) return '';
    return string.replace(/[^a-zA-Z0-9]/g, '_');
  }

  /**
   * Check if file is shared with the given consultant.
   * @param consultant is the email of the consultant.
   * @param file is the file to be analyzed.
   */
  fileIsShared(consultant, file) {
    if (!file.sharedUsers) {
      return false;
    }
    for (let index = 0; index < file.sharedUsers.length; index++) {
      const sharedUser = file.sharedUsers[index];
      if (sharedUser.email.toLowerCase() === consultant.email.toLowerCase()) {
        return true;
      }
    }
    return false;
  }

  dateStringToFormattedString(datestring: string) {
    const year = parseInt(datestring.substr(0, 4), 10);
    const month = parseInt(datestring.substr(4, 2), 10);
    const day = parseInt(datestring.substr(6, 2), 10);
    return `${month}/${day}/${year}`;
  }

  setDateToString(dateValue) {
    return `${(dateValue.getMonth() + 1).toString()}${dateValue.getDate().toString()}${dateValue
      .getFullYear()
      .toString()}`;
  }

  getDateToString(dateValue: Date) {
    return `${(dateValue.getMonth() + 1).toString()}/${dateValue.getDate().toString()}/${dateValue
      .getFullYear()
      .toString()}`;
  }

  getNewStoreName_complement(date: string) {
    const randNum = Math.floor(Math.random() * Math.floor(950));
    // TODO: save this storename in firebase.
    return `_${date}_${randNum}`;
  }

  toCamelCase(str) {
    return (str.slice(0, 1).toLowerCase() + str.slice(1))
      .replace(/([-_ ]){1,}/g, ' ')
      .split(/[-_ ]/)
      .reduce((cur, acc) => {
        return cur + acc[0].toUpperCase() + acc.substring(1);
      });
  }

  hasValidExt(filename, allowed) {
    // Avoiding ._XYZ files for Mac File Systems
    if (filename.indexOf('.') === 0) {
      return false;
    }
    // Avoiding VIDEO_TS file
    if (filename.substr(0, 9).toUpperCase() === 'VIDEO_TS.') {
      return false;
    }

    const upperCaseAllowed = allowed.map(val => val.toUpperCase());
    if (allowed.indexOf('***') >= 0) {
      // ['***']
      if (filename.indexOf('.') === -1) {
        return true;
      } else {
        return false;
      }
    } else if (allowed.indexOf('**') >= 0) {
      // ['**']
      if (filename.indexOf('.') === -1) {
        return false;
      } else {
        return true;
      }
    } else if (allowed.indexOf('*') > -1) {
      // ['*']
      return true;
    }

    if (allowed.length === 0) {
      return false;
    }
    const dot = filename.lastIndexOf('.');
    if (dot === -1) {
      return false;
    }
    const ext = filename.slice(dot + 1);
    if (upperCaseAllowed.indexOf(ext.toUpperCase()) === -1) {
      return false;
    }
    return true;
  }

  getCurrentDate() {
    const today = new Date();
    const dd = String(today.getDate()).padStart(2, '0');
    const mm = String(today.getMonth() + 1).padStart(2, '0');
    const yyyy = today.getFullYear();
    return mm + '/' + dd + '/' + yyyy;
  }

  /**
   * Gets a part of the viewerurl.
   */
  viewerurlToDicomStore(viewerurl: string, vieweUrlPart: number): string {
    const string1 = 'https://';
    const pos = viewerurl.indexOf(string1);
    const length = pos >= 0 ? pos + string1.length : string1.length;
    const cleanstring = viewerurl.substring(length).replace(/(\/\/)/g, '');
    let result = '';

    if (vieweUrlPart === 8) {
      // Split the string by '/' and get the part next to "dicomStores"
      const parts = cleanstring.split('/');
      for (let i = 0; i < parts.length; i++) {
        if (parts[i] === 'dicomStores') {
          result = parts[i + 1];
          break;
        }
      }
    } else {
      result = cleanstring.split('/')[vieweUrlPart - 1];
    }
    return result;
  }

  wait(arg0: number) {
    return new Promise(resolve => setTimeout(resolve, arg0));
  }

  timeToString(studyTime: string) {
    // Extract hours, minutes, and seconds
    const hours = studyTime.substring(0, 2);
    const minutes = studyTime.substring(2, 4);
    const seconds = studyTime.substring(4, 6);

    // Format the time
    const formattedTime = `${hours}:${minutes}:${seconds}`;

    return formattedTime;
  }
}
