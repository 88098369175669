import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { LoaderComponent } from 'src/app/loader/loader.component';

import { AuthService } from '../../services/auth.service';

@Component({
  selector: 'app-fgpassword',
  templateUrl: './fgpassword.component.html',
  styleUrls: ['./fgpassword.component.scss'],
})
export class FgpasswordComponent implements OnInit {
  errorMessage: string;
  loading: boolean;
  loader: MatDialogRef<LoaderComponent, any>;
  forgotEmail = new FormGroup({
    email: new FormControl('', [Validators.required, Validators.email]),
  });

  constructor(
    public auth_$: AuthService,
    public dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) {}

  ngOnInit() {
    this.forgotEmail.controls.email.setValue(this.data.email);
  }

  emailHasError() {
    return this.forgotEmail.controls.email.invalid;
  }

  getErrorMessage(): string {
    if (this.forgotEmail.controls.email.hasError('required')) return 'You must enter a value';
    return this.forgotEmail.controls.email.hasError('email') ? 'Not a valid email' : '';
  }

  onSubmit() {
    this.auth_$.showLoader('Sending password reset email...');
    this.loading = true;
    const emailValue = new String(this.forgotEmail.controls.email.value);
    this.auth_$.passwordForgetEmail(emailValue.trim().toLowerCase()).then(() => {
      this.auth_$.hideLoader();
      this.loading = false;
    });
  }
}
