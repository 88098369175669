import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { environment } from 'src/environments/environment';

import { ClientProfileComponent } from './components/clientprofile/clientprofile.component';
import { ClioAuthComponent } from './components/clio/ClioAuth/ClioAuth.component';
import { CompleteClioRegistrationComponent } from './components/clio/complete-clio-registration/complete-clio-registration.component';
import { CompleteNuagedxRegistrationComponent } from './components/clio/complete-nuagedx-registration/complete-nuagedx-registration.component';
import { HandleClioCustomActionsComponent } from './components/clio/handle-clio-custom-actions/handle-clio-custom-actions.component';
import { CreateadminComponent } from './components/createadmin/createadmincomponent';
import { CreateclientComponent } from './components/createclient/createclient.component';
import { CreatepatientComponent } from './components/createpatient/createpatient.component';
import { CreateuserComponent } from './components/createuser/createuser.component';
import { FgpasswordComponent } from './components/fgpassword/fgpassword.component';
import { MaintenancePageComponent } from './components/maintenance/maintenance.page';
import { MeetingsPageComponent } from './components/meetings-page/meetings-page.component';
import { PracticePantherAuthComponent } from './components/practicepanther/PracticePantherAuth/PracticePantherAuth.component';
import { ReportComponent } from './components/report/report.component';
import { ResetPasswordComponent } from './components/reset-password/reset-password.component';
import { SearchComponent } from './components/search/search.component';
import { SysadminComponent } from './components/sysadmin/sysadmin.component';
import { CustomActions } from './dictionaries/CustomActions';
import { HomePageComponent } from './home-page/home-page.component';
import { LoginPageComponent } from './login-page/login-page.component';
import { AuthGuardService } from './services/auth-guard.service';

const routes: Routes = [
  { component: LoginPageComponent, path: 'login' },
  { component: ResetPasswordComponent, path: 'validateEmail' },
  { component: LoginPageComponent, path: 'login_eu' },
  { component: LoginPageComponent, path: 'login_eu/:sso' },
  { component: LoginPageComponent, path: 'login/:sso' },
  { component: LoginPageComponent, path: 'logout' },
  { component: CreateclientComponent, path: 'cclient' },
  { component: CreateuserComponent, path: 'cuser' },
  { component: CreateadminComponent, path: 'cadmin' },
  { component: FgpasswordComponent, path: 'fgpass' },
  { component: CreatepatientComponent, path: 'cpatient' },
  { component: SearchComponent, path: 'clients/:uid/:email/:realRole' },
  { component: ClientProfileComponent, path: 'profile/:uid' },
  {
    component: HandleClioCustomActionsComponent,
    path: CustomActions.uploadDicomDisk,
    canActivate: [AuthGuardService],
  },
  {
    component: HandleClioCustomActionsComponent,
    path: CustomActions.viewMedicalImages,
    canActivate: [AuthGuardService],
  },
  {
    component: HandleClioCustomActionsComponent,
    path: CustomActions.meetingsScheduling,
    canActivate: [AuthGuardService],
  },
  {
    component: HandleClioCustomActionsComponent,
    path: CustomActions.openClioMatter,
    canActivate: [AuthGuardService],
  },
  {
    component: HandleClioCustomActionsComponent,
    path: CustomActions.openClioDocument,
    canActivate: [AuthGuardService],
  },
  {
    component: HandleClioCustomActionsComponent,
    path: CustomActions.meetingsScheduling,
    canActivate: [AuthGuardService],
  },
  {
    component: HandleClioCustomActionsComponent,
    path: CustomActions.openClioMatterFromDocument,
    canActivate: [AuthGuardService],
  },
  {
    component: HandleClioCustomActionsComponent,
    path: CustomActions.goToNuageDx,
    canActivate: [AuthGuardService],
  },
  { component: HomePageComponent, path: 'admins', canActivate: [AuthGuardService] },
  { component: HomePageComponent, path: 'clients', canActivate: [AuthGuardService] },
  { component: HomePageComponent, path: 'clientsmatters', canActivate: [AuthGuardService] },
  { component: HomePageComponent, path: 'createclientmatter' },
  { component: HomePageComponent, path: 'createadminuser', canActivate: [AuthGuardService] },
  { component: HomePageComponent, path: 'createclientsmatters', canActivate: [AuthGuardService] },
  { component: HomePageComponent, path: 'attorneys_lawfirms' },
  { component: HomePageComponent, path: 'lpmwelcome' },
  { component: HomePageComponent, path: 'associates', canActivate: [AuthGuardService] },
  { component: HomePageComponent, path: 'owners', canActivate: [AuthGuardService] },
  { component: HomePageComponent, path: 'consultants', canActivate: [AuthGuardService] },
  { component: HomePageComponent, path: '' },
  { component: ClioAuthComponent, path: environment.config.clio.SSO.usCallbackStr },
  { component: ClioAuthComponent, path: environment.config.clio.SSO.euCallbackStr },
  { component: ClioAuthComponent, path: 'clioauth/:origin' },
  { component: ReportComponent, path: 'report/:uid' },
  { component: MeetingsPageComponent, path: 'meetings', canActivate: [AuthGuardService] },
  { component: SearchComponent, path: 'clients', canActivate: [AuthGuardService] },
  { component: CompleteClioRegistrationComponent, path: 'completeclioregistration', canActivate: [AuthGuardService] },
  { component: PracticePantherAuthComponent, path: 'practicepantherauth' },
  { component: ResetPasswordComponent, path: 'resetpassword' },
  {
    component: CompleteNuagedxRegistrationComponent,
    path: 'completenuagedxregistration',
    canActivate: [AuthGuardService],
  },
];

if (environment.config.sysadmin) routes.push({ component: SysadminComponent, path: 'sysadmin' });
if (environment.maintenance) routes.push({ component: MaintenancePageComponent, path: '**' });
else routes.push({ component: HomePageComponent, path: '**' });

@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
  exports: [RouterModule],
})
export class AppRoutingModule {}
