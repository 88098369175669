<div fxLayout="row"
     fxLayoutAlign="space-around center">
  <div *ngIf="showform === 2">
    <h2>
      Reset your password
    </h2>
    <form class="change-password-form"
          (ngSubmit)="handleSubmit($event)">
      <mat-form-field appearance="fill">
        <mat-label>
          New password
        </mat-label>
        <mat-pass-toggle-visibility #toggle
                                    matSuffix></mat-pass-toggle-visibility>
        <input id="password"
               class="password-input"
               name="password"
               placeholder="Enter password"
               matInput
               [formControl]="password"
               [type]="toggle.type">
      </mat-form-field>
      <mat-password-strength #passwordComponent
                             [password]="password.value"
                             (onStrengthChanged)="onStrengthChanged($event)"></mat-password-strength>
      <mat-password-strength-info [passwordComponent]="passwordComponent"></mat-password-strength-info>
      <mat-form-field appearance="fill">
        <mat-label>
          Confirm password
        </mat-label>
        <mat-pass-toggle-visibility #toggle_cp
                                    matSuffix></mat-pass-toggle-visibility>
        <input class="password-input"
               name="confirmpassword"
               matInput
               [formControl]="passwordconfirm"
               [type]="toggle_cp.type"
               (input)="checkPasswordConfirm($event)">
      </mat-form-field>
      <button color="warn"
              type="submit"
              mat-flat-button
              [disabled]="!submitApproval">
        CHANGE PASSWORD
      </button>
    </form>
  </div>
  <p *ngIf="showform === 1">
    The link is outdated, please try again, you will be redirected soon.
  </p>
</div>
