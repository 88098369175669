<app-window-title [closable]="true"
                  [title]="'Forgot Password'"></app-window-title>
<form [formGroup]="forgotEmail"
      (ngSubmit)="onSubmit()">
  <div mat-dialog-content>
    <div fxLayout="column"
         fxLayoutAlign="center center">
      <p>
        Enter your email and we will send you a password reset link
      </p>
      <mat-form-field class="full-width"
                      color="warn"
                      appearance="fill">
        <mat-label>
          Email
        </mat-label>
        <input color="warn"
               placeholder="Enter a valid email address"
               required
               formControlName="email"
               matInput>
        <mat-error *ngIf="emailHasError()">
          {{ getErrorMessage() }}
        </mat-error>
      </mat-form-field>
    </div>
  </div>

  <button color="warn"
          type="button"
          type="submit"
          mat-flat-button
          [disabled]="forgotEmail.controls.email.invalid || loading">
    RESET PASSWORD
  </button>
</form>
